/* eslint-disable react/jsx-props-no-spreading */
import { Menu as AntdMenu } from 'antd'
import PropTypes from 'prop-types'

import './Menu.less'

const propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      descricao: PropTypes.string.isRequired,
      idgrupo: PropTypes.string.isRequired
    })
  ).isRequired
}

const Menu = ({ menu, ...antdMenuProps }) => (
  <AntdMenu
    {...antdMenuProps}
    className="menu__container"
    items={menu.map(({ descricao, idgrupo }) => ({
      key: `grupo-${idgrupo}`,
      label: descricao
    }))}
  />
)

Menu.propTypes = propTypes

export default Menu
