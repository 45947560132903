import { useState } from 'react'
import { Tag, Typography } from 'antd'
import PropTypes from 'prop-types'

import Preco from './Preco'
import ProdutoDetalhe from './ProdutoDetalhe'
import ProdutoDrawer from './ProdutoDrawer'
import ProdutoImagemPadrao from './ProdutoImagemPadrao'

import './Produto.less'

const { Text } = Typography

const propTypes = {
  produto: PropTypes.shape({
    descricao: PropTypes.string.isRequired,
    detalhe: PropTypes.string,
    grupoopcoes: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.number
      })
    ),
    imagem: PropTypes.string,
    stindisponivel: PropTypes.number.isRequired
  }).isRequired,
  opcoesGrupoProduto: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number
    })
  )
}

const defaultProps = {
  opcoesGrupoProduto: []
}

const Produto = ({ produto, opcoesGrupoProduto }) => {
  const [visible, setVisible] = useState(false)

  const closeDrawer = () => {
    setVisible(false)
  }

  const openDrawer = () => {
    setVisible(true)
  }

  return (
    <>
      <div
        aria-hidden="true"
        className="produto__container"
        onClick={openDrawer}
      >
        <div className="produto__info">
          <Text className="produto__info__descricao">{produto.descricao}</Text>

          <Text className="produto__info__detalhe" type="secondary">
            <ProdutoDetalhe detalhe={produto.detalhe} />
          </Text>

          <div className="produto__info__preco">
            <Preco abreviar item={produto} />

            {(produto.stindisponivel === 0 && produto.grupoopcoes.length > 0) ||
            opcoesGrupoProduto.length > 0 ? (
              <Tag className="produto__info__preco__tag" color="success">
                Opcionais
              </Tag>
            ) : null}

            {produto.stindisponivel === 1 ? (
              <Tag className="produto__info__preco__tag" color="error">
                Indisponível
              </Tag>
            ) : null}
          </div>
        </div>

        <div className="produto__imagem">
          {produto.imagem !== null ? (
            <img
              loading="lazy"
              src={`${process.env.REACT_APP_API_URL}${produto.imagem}`}
              alt={produto.descricao}
            />
          ) : (
            <ProdutoImagemPadrao />
          )}
        </div>
      </div>

      <ProdutoDrawer
        produto={produto}
        opcoesGrupoProduto={opcoesGrupoProduto}
        title={produto.descricao}
        visible={visible}
        onClose={closeDrawer}
      />
    </>
  )
}

Produto.propTypes = propTypes
Produto.defaultProps = defaultProps

export default Produto
