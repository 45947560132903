import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import ajustarGrupo from 'App/utils/ajustarGrupo'

const authenticate = () =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/auth/app/token/`, {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET
    })
    .then((response) => response.data)

const fetchMenu = (accessToken, idestabelecimento) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/pedido/v1/estabelecimento/${idestabelecimento}/menu/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    .then((response) => response.data.grupos)
    .then((menu) =>
      menu.map((grupo) => {
        const { produtos } = grupo

        return {
          ...grupo,
          produtos: produtos.filter((produto) => produto.stindisponivel !== 2)
        }
      })
    )
    .then((menu) => menu.filter((grupo) => grupo.produtos.length > 0))
    .then((menu) => menu.map(ajustarGrupo))

const useMenu = (idestabelecimento) => {
  const { data: authData, isError: authError } = useQuery(['auth'], () =>
    authenticate()
  )

  const accessToken = authData?.access

  const { data, isError, isLoading } = useQuery(
    ['menu', accessToken, idestabelecimento],
    () => fetchMenu(accessToken, idestabelecimento.replace('-', '')),
    { enabled: !!accessToken }
  )

  return {
    menuRequest: { data, isError: authError || isError, isLoading }
  }
}

export default useMenu
