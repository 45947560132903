/* eslint-disable camelcase */
const ajustarPreco = (preco) =>
  new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'brl'
  }).format(preco)

const ajustarOpcao = (opcao) => {
  const { idopcao_str, preco, precooferta, ...props } = opcao

  return {
    ...props,
    idopcao: idopcao_str,
    preco,
    preco_str: ajustarPreco(preco),
    precooferta,
    precooferta_str: ajustarPreco(precooferta)
  }
}

const ajustarGrupoOpcao = (grupoopcao) => {
  const { idgrupoopcao_str, opcoes, ...props } = grupoopcao

  return {
    ...props,
    idgrupoopcao: idgrupoopcao_str,
    opcoes: opcoes.map(ajustarOpcao)
  }
}

const ajustarProduto = (produto) => {
  const { grupoopcoes, idproduto_str, preco, precooferta, ...props } = produto

  return {
    ...props,
    grupoopcoes: grupoopcoes.map(ajustarGrupoOpcao),
    idproduto: idproduto_str,
    preco,
    preco_str: ajustarPreco(preco),
    precooferta,
    precooferta_str: ajustarPreco(precooferta)
  }
}

const ajustarGrupo = (grupo) => {
  const { idgrupo_str, produtos, grupoopcoes, ...props } = grupo

  return {
    ...props,
    idgrupo: idgrupo_str,
    produtos: produtos.map(ajustarProduto),
    grupoopcoes: grupoopcoes.map(ajustarGrupoOpcao)
  }
}

export default ajustarGrupo
