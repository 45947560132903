import PropTypes from 'prop-types'

import useViewport from 'App/hooks/useViewport'

const propTypes = {
  detalhe: PropTypes.string
}

const defaultProps = {
  detalhe: null
}

const ProdutoDetalhe = ({ detalhe }) => {
  const { width } = useViewport()

  if (!detalhe || typeof detalhe !== 'string') {
    return null
  }

  if (detalhe.length > 64) {
    return `${detalhe.slice(0, width < 576 ? 64 : 128)}...`
  }

  return detalhe
}

ProdutoDetalhe.propTypes = propTypes
ProdutoDetalhe.defaultProps = defaultProps

export default ProdutoDetalhe
