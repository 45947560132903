import { Spin } from 'antd'

import './FullPageLoader.less'

const FullPageLoader = () => (
  <div className="full-page-loader__container">
    <Spin tip="Carregando o menu do estabelecimento" />
  </div>
)

export default FullPageLoader
