/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
import { Fragment } from 'react'
import { Avatar, Drawer, List, Space, Typography } from 'antd'
import { CameraOutlined } from '@ant-design/icons'

import useViewport from 'App/hooks/useViewport'

import Preco from './Preco'

import './ProdutoDrawer.less'

const { Paragraph, Text, Title } = Typography

const mergeOpcoes = (grupoOpcoes1, grupoOpcoes2) => {
  const merged = [...grupoOpcoes1]

  grupoOpcoes2.forEach((grupo2) => {
    const grupoExistente = merged.find(
      (grupo1) => grupo1.idgrupoopcao === grupo2.idgrupoopcao
    )

    if (grupoExistente) {
      grupoExistente.opcoes = [
        ...grupoExistente.opcoes,
        ...grupo2.opcoes.filter(
          (opcao2) =>
            !grupoExistente.opcoes.some(
              (opcao1) => opcao1.idopcao === opcao2.idopcao
            )
        )
      ]
    } else {
      merged.push(grupo2)
    }
  })

  return merged
}

const ProdutoDrawer = ({ produto, opcoesGrupoProduto, ...antdDrawerProps }) => {
  const { width } = useViewport()

  let content = null

  let grupoOpcoesMescladas = []

  if (produto?.grupoopcoes?.length > 0 && opcoesGrupoProduto?.length > 0) {
    grupoOpcoesMescladas = mergeOpcoes(produto.grupoopcoes, opcoesGrupoProduto)
  } else if (produto?.grupoopcoes?.length > 0) {
    grupoOpcoesMescladas = produto.grupoopcoes
  } else if (opcoesGrupoProduto?.length > 0) {
    grupoOpcoesMescladas = opcoesGrupoProduto
  }

  if (grupoOpcoesMescladas.length > 0) {
    content = (
      <div>
        <Title level={4}>Opcionais</Title>
        {grupoOpcoesMescladas.map((grupoopcao) => (
          <Fragment key={grupoopcao.idgrupoopcao}>
            <Text strong>{grupoopcao.descricao}</Text>
            <List
              dataSource={grupoopcao.opcoes}
              renderItem={(opcao) => (
                <List.Item>
                  <List.Item.Meta
                    title={opcao.descricao}
                    description={<Preco item={opcao} />}
                  />
                </List.Item>
              )}
              split={false}
            />
          </Fragment>
        ))}
      </div>
    )
  }

  return (
    <Drawer
      {...antdDrawerProps}
      bodyStyle={{ padding: '1rem' }}
      className="produto-drawer__container"
      headerStyle={{
        flex: 'none',
        height: '64px',
        padding: '16px'
      }}
      width={width < 576 ? width : 576}
    >
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <Space
          align="center"
          direction="vertical"
          size={8}
          style={{ width: '100%' }}
        >
          {produto.imagem !== null ? (
            <Avatar
              size={128 * (width < 576 ? 1.5 : 2)}
              src={`${process.env.REACT_APP_API_URL}${produto.imagem}`}
              alt={produto.descricao}
            />
          ) : (
            <Avatar
              icon={<CameraOutlined />}
              size={128 * (width < 576 ? 1.5 : 2)}
            />
          )}

          <Preco item={produto} />
        </Space>

        {produto.detalhe ? (
          <div>
            <Title level={4}>Detalhes</Title>
            <Paragraph type="secondary">{produto.detalhe}</Paragraph>
          </div>
        ) : null}

        {content}
      </Space>
    </Drawer>
  )
}

export default ProdutoDrawer
