import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import CardapioLegadoPage from './pages/CardapioLegadoPage'
import CardapioPage from './pages/CardapioPage'

import './index.less'

const root = ReactDOM.createRoot(document.getElementById('root'))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/relatorio/estabelecimento/:idestabelecimento/cardapio"
            element={<CardapioLegadoPage />}
          />

          <Route
            path="/estabelecimento/:idestabelecimento/cardapio"
            element={<CardapioPage />}
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
)
