import { useState } from 'react'
import { Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import Menu from './Menu'

import './CollapsibleMenu.less'

const propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      descricao: PropTypes.string.isRequired,
      idgrupo: PropTypes.string.isRequired
    })
  ).isRequired
}

const CollapsibleMenu = ({ menu }) => {
  const [visible, setVisible] = useState(false)

  const closeMenu = () => {
    setVisible(false)
  }

  const openMenu = () => {
    setVisible(true)
  }

  const closeMenuAndScrollToGroupSection = ({ key }) => {
    closeMenu()

    document.getElementById(key).scrollIntoView(true)
  }

  return (
    <>
      <MenuOutlined className="collapsible-menu__trigger" onClick={openMenu} />

      <Drawer
        bodyStyle={{ backgroundColor: '#ff4d4f', padding: 0 }}
        className="collapsible-menu__drawer"
        headerStyle={{
          backgroundColor: '#ff4d4f',
          border: 'none',
          flex: 'none',
          height: '64px',
          padding: '16px'
        }}
        placement="left"
        visible={visible}
        onClose={closeMenu}
      >
        <Menu menu={menu} onClick={closeMenuAndScrollToGroupSection} />
      </Drawer>
    </>
  )
}

CollapsibleMenu.propTypes = propTypes

export default CollapsibleMenu
