import { CameraOutlined } from '@ant-design/icons'

import './ProdutoImagemPadrao.less'

const ProdutoImagemPadrao = () => (
  <div className="produto-imagem-padrao__container">
    <CameraOutlined style={{ fontSize: 64 }} />
  </div>
)

export default ProdutoImagemPadrao
