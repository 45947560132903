import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const authenticate = () =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/auth/app/token/`, {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET
    })
    .then((response) => response.data)

const fetchLogoMenu = (accessToken, idestabelecimento) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/cadastro/v1/estabelecimento/${idestabelecimento}/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    .then((response) => response.data.imgestab_set)

const useLogoMenu = (idestabelecimento) => {
  const { data: authData, isError: authError } = useQuery(['auth'], () =>
    authenticate()
  )

  const accessToken = authData?.access

  const { data, isError, isLoading } = useQuery(
    ['logoMenu', accessToken, idestabelecimento],
    () => fetchLogoMenu(accessToken, idestabelecimento.replace('-', '')),
    { enabled: !!accessToken }
  )

  return {
    logoMenuRequest: { data, isError: authError || isError, isLoading }
  }
}

export default useLogoMenu
