import { Typography } from 'antd'
import PropTypes from 'prop-types'

import useViewport from 'App/hooks/useViewport'

const { Text } = Typography

const propTypes = {
  abreviar: PropTypes.bool,
  item: PropTypes.shape({
    preco_str: PropTypes.string,
    precooferta: PropTypes.number,
    precooferta_str: PropTypes.string
  }).isRequired
}

const defaultProps = {
  abreviar: false
}

const Preco = ({ abreviar, item }) => {
  const { width } = useViewport()

  if (typeof item.precooferta === 'number' && item.precooferta > 0) {
    return abreviar && width < 576 ? (
      <Text type="success">{item.precooferta_str}</Text>
    ) : (
      <Text>
        De <Text delete>{item.preco_str}</Text> por{' '}
        <Text type="success">{item.precooferta_str}</Text>
      </Text>
    )
  }

  return <Text>{item.preco_str}</Text>
}

Preco.propTypes = propTypes
Preco.defaultProps = defaultProps

export default Preco
