import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BackTop, Layout, Image } from 'antd'

import CollapsibleMenu from 'App/components/CollapsibleMenu'
import FullPageLoader from 'App/components/FullPageLoader'
import Grupo from 'App/components/Grupo'
import Menu from 'App/components/Menu'
import MenuRequestErrorPage from 'App/components/MenuRequestErrorPage'

import useLogoMenu from 'App/hooks/useLogoMenu'
import useMenu from 'App/hooks/useMenu'
import useViewport from 'App/hooks/useViewport'

import './CardapioPage.less'

const { Content, Header, Sider } = Layout

const MAX_SIZE_WIDTH = 130
const MAX_SIZE_HEIGHT = 75

const CardapioPage = () => {
  const { idestabelecimento } = useParams()
  const { menuRequest } = useMenu(idestabelecimento)
  const { logoMenuRequest } = useLogoMenu(idestabelecimento)

  const { width } = useViewport()
  const [imageSize, setImageSize] = useState({
    width: MAX_SIZE_WIDTH,
    height: MAX_SIZE_HEIGHT
  })

  const IMAGE_URL = logoMenuRequest.data?.[0]?.img

  useEffect(() => {
    const img = new window.Image()
    img.src = IMAGE_URL
    img.onload = () => {
      if (img.width > MAX_SIZE_WIDTH || img.height > MAX_SIZE_HEIGHT) {
        setImageSize({ width: MAX_SIZE_WIDTH, height: MAX_SIZE_HEIGHT })
      } else {
        setImageSize({ width: img.width, height: img.height })
      }
    }
  }, [IMAGE_URL])

  if (menuRequest.isError) {
    return <MenuRequestErrorPage />
  }

  if (menuRequest.isLoading) {
    return <FullPageLoader />
  }

  const scrollToGroupSection = ({ key }) => {
    document.getElementById(key).scrollIntoView(true)
  }

  return (
    <Layout>
      <Header
        className="cardapio__header"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          height: '5.313rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        {width < 992 ? (
          <div style={{ flex: 1 }}>
            <CollapsibleMenu menu={menuRequest.data} />
          </div>
        ) : null}
        <div>
          <Image
            src={IMAGE_URL}
            preview={false}
            style={{
              width: imageSize.width,
              height: imageSize.height,
              objectFit: 'contain'
            }}
          />
        </div>
      </Header>

      <Layout>
        {width >= 992 ? (
          <Sider width={992 / 4}>
            <Menu menu={menuRequest.data} onClick={scrollToGroupSection} />
          </Sider>
        ) : null}

        <Content className="cardapio__content">
          {menuRequest.data.map((grupo) => (
            <Grupo key={grupo.idgrupo} grupo={grupo} />
          ))}
        </Content>
      </Layout>

      <BackTop style={{ bottom: '1rem', right: '1rem' }} />
    </Layout>
  )
}

export default CardapioPage
