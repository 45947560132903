/* eslint-disable react/prop-types */
import { Col, Row, Typography } from 'antd'

import Produto from './Produto'

import './Grupo.less'

const { Title } = Typography

const Grupo = ({ grupo }) => {
  const opcoesGrupoProduto = grupo?.grupoopcoes.map((grupoopcao) => grupoopcao)

  return (
    <section className="grupo__container" id={`grupo-${grupo.idgrupo}`}>
      <Title className="grupo__descricao" level={3}>
        {grupo.descricao}
      </Title>

      <Row gutter={[16, 16]}>
        {grupo.produtos.map((produto) => (
          <Col key={produto.idproduto} xs={24} xl={12}>
            <Produto
              produto={produto}
              opcoesGrupoProduto={opcoesGrupoProduto}
            />
          </Col>
        ))}
      </Row>
    </section>
  )
}

export default Grupo
