import { Button, Result } from 'antd'

const MenuRequestErrorPage = () => (
  <Result
    extra={
      <Button
        onClick={() => {
          window.location.reload()
        }}
      >
        Recarregar a página
      </Button>
    }
    status="error"
    subTitle="Por favor, tente novamente."
    title="Erro ao carregar o menu."
  />
)

export default MenuRequestErrorPage
